import { resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    ref: "searchInputRef",
    modelValue: _ctx.modelInput,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelInput) = $event)),
    onKeyup: _withKeys(_ctx.search, ["enter"])
  }, null, 8, ["modelValue", "onKeyup"]))
}